<template>
  <div>
    <Collapse />
  </div>
</template>

<script>
import Collapse from '../../pages/vue-bootstrap/Collapse'

export default {
  name: 'FaqPage',

  components: {
    Collapse
  }
}
</script>

<style lang="scss" scoped></style>
