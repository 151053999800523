<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <b-spinner
        label="Loading..."
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12 my-5">
          <h2>Perguntas frequentes</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <template>
            <div role="tablist">
              <b-card v-for="(item, k) in items" no-body class="mb-1" :key="k">
                <b-card-header
                  header-tag="header"
                  class="p-1 justify-content-between align-center"
                  role="tab"
                  v-b-toggle="[`accordion-${k}`]"
                >
                  <div class="card-title">
                    <i :class="item.icon" style="margin-right: 10px"></i>
                    {{ item.title }}
                  </div>
                </b-card-header>
                <b-collapse
                  :id="`accordion-${k}`"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div v-html="item.text"></div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: true,
    text: null,
    items: []
  }),

  methods: {
    fetchFaqs() {
      this.$http
        .get('faqs')
        .then(({ data }) => (this.items = data))
        .finally(() => (this.loading = false))
    }
  },

  mounted() {
    this.fetchFaqs()
  }
}
</script>

<style lang="css">
.card-title {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}
</style>
